import React, { useState, useEffect } from "react";
import "./Section5.css";
import treesDI from "../images/di-trees.png";
import kidsDI from "../images/di-kids.png";
import schoolDI from "../images/di-school.png";
import artDI from "../images/di-art.png";

function Section5() {
  const handleClick = () => {
    window.open(
      "https://donor.southtexasblood.org/donor/store/preview?utm_source=double_my_impact&utm_medium=landing_page&utm_campaign=twa_dontate&utm_content=stb&t",
      "_blan"
    );
  };

  return (
    <div className="planting-container">
      <div className="overlay">
        <div className="planting-title">
          Our Impact on the <br />
          Community
        </div>
        <div className="planting-p">
          During 2023, Double Your Impact has worked with many San Antonio
          organizations to accomplish some amazing and things. And with your
          continued support, we are just getting started.
        </div>
        <div className="grid">
          <div className="item">
            <img src={treesDI} alt="Trees Double Impact" />
            <div className="item-caption">
              Last year, you helped plant{" "}
              <span className="color-text">1,045 Trees in Texas</span>
            </div>
          </div>
          <div className="item">
            <img src={kidsDI} alt="Kids Double Impact" />
            <div className="item-caption">
              You helped Send&nbsp;
              <span className="color-text">23 Kids to Camp Discovery</span>
            </div>
          </div>
          <div className="item">
            <img src={schoolDI} alt="School Double Impact" />
            <div className="item-caption">
              You helped raise&nbsp;
              <span className="color-text">over 13k of School Supplies</span>
            </div>
          </div>
          <div className="item">
            <img src={artDI} alt="Art Double Impact" />
            <div className="item-caption">
              You helped raise almost&nbsp;
              <span className="color-text">
                7k for the Guadalupe Cultural Art Center
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section5;
