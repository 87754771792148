import React from "react";
import "./Section3.css";
import joyLogo from "../images/joy-logo.png";

function Section3() {
  const campClick = () => {
    window.open("https://adltexas.org/", "_blank");
  };
  return (
    <div className="joy-container">
      <div className="joy-image"></div>
      <div className="joy-day-container">
        <div className="sec3-text-container">
          <img src={joyLogo} alt="Animal Defense Logo" className="joy-logo" />
          <div className="joy-p">
            The Animal Defense League of Texas (ADL) is a non-profit
            organization that rescues homeless dogs, cats, puppies, and kittens,
            and provides medical treatment, nutrition, and shelter in a safe,
            live-release facility until they are matched with their forever
            families. Through community education and empowerment, we advocate
            for responsible pet ownership.
          </div>
        </div>
        <button
          className="secondary-donate-btn special-btn"
          onClick={campClick}
        >
          FIND OUT MORE
        </button>
      </div>
    </div>
  );
}

export default Section3;
