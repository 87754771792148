import React from "react";
import "./Section4.css";
import Image3 from "../images/pic3.png";

function Section4() {
  const handleClick = () => {
    window.open(
      "https://donor.southtexasblood.org/donor/store/preview?utm_source=double_my_impact&utm_medium=landing_page&utm_campaign=twa_dontate&utm_content=stb&t",
      "_blan"
    );
  };
  return (
    <div className="climb-container">
      <div className="image3-container">
        <img src={Image3} alt="Section 3 Image" />
      </div>
      <div className="climb-content">
        <div className="climb-title">
          Why Donate to the Animal Defense League of Texas?
          {/* <span className="color-text">They are our future!”</span> */}
        </div>
        <div className="climb-p">
          The ADL is San Antonio’s oldest no-kill shelter. No rescue is ever
          euthanized if it can be treated and returned to health. If the shelter
          reaches its maximum capacity, no additional animals will be taken in,
          but no rescue will ever be euthanized to make room for others.
        </div>
        <button className="donate-btn3 donate-btn" onClick={handleClick}>
          Donate Points
        </button>
      </div>
    </div>
  );
}

export default Section4;
